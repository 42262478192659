import React from "react";
import {Link} from "react-router-dom";

function Card(props) {
  return (
    <>
      <div className=" col-md-4 col-10 mx-auto ">
        <div className="card">
          <img 
            
            src={props.imgsrc}
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title font-weight-bolder">{props.title}</h5>
            <p className="card-text">
              {props.desc}
            </p>
            <Link to="/services" className="btn btn-primary">
              {props.btn}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
