import React from 'react'

function Footer() {
  return (
   <>
   <footer className='bg-light text-center my-40'>
        <p>&copy; 2022 DahalTechnical. All Right | Terms and Condition are Reserved</p>
   </footer>
   </>
  )
}

export default Footer