const Sdata = [
    {
        imgsrc : "https://images.pexels.com/photos/251225/pexels-photo-251225.jpeg?auto=compress&cs=tinysrgb&w=1260&h=880&dpr=1",
        title:"Web-Development",
      desc:"Web development is the work involved in developing a website for the Internet (World Wide Web) or an intranet (a private network).[1] Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services. A more comprehensive list of tasks to which "

    },
    {
        imgsrc:"https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title:"App Development",
        desc:"Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones. These software applications are designed to run on mobile devices, such as a smartphone or tablet computer. These applications can be pre-installed on phones"
    },
    {
        imgsrc : "https://images.pexels.com/photos/1089440/pexels-photo-1089440.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"Software Development",
        desc:"Software development is the process of conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components. Software development involves writing and maintaining the source code, but in a broader sense, it includes all processes "
    },
    {
        imgsrc : "https://thumbs.dreamstime.com/z/digital-marketing-concept-business-development-lead-generation-social-network-media-communication-strategy-banner-170866427.jpg",
        title:"Digital Marketing",
        desc:"Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services.[2][3] Its development   "
    },
    {
        imgsrc : "https://images.pexels.com/photos/267389/pexels-photo-267389.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"Android Development",
        desc:"Android software development is the process by which applications are created for devices running the Android operating system. Google states that[3] 'Android apps can be written using Kotlin, Java, and C++ languages' using the Android software development kit  "
    },
    {
        imgsrc : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMqADbc5og8x1JOTUYzvXpiou_0XIhnFlrSQ&usqp=CAU",
        title:"Marketing",
        desc:"Marketing is the process of exploring, creating, and delivering value to meet the needs of a target market in terms of goods and services;[1][2] potentially including selection of a target audience; selection of certain attributes or themes to emphasize in advertising; "
    },
]
export default Sdata;