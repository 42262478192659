import './App.css';
import {Routes,Route} from 'react-router-dom';
import Navbar from './components/Nabvar/Navbar';
import Home from './components/Home/Home';
import'../node_modules/bootstrap/dist/css/bootstrap.min.css'
import'../node_modules/bootstrap/dist/js/bootstrap.bundle'
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer';
function App() {
  return (
    <>
    <Navbar/>
        <Routes>
          <Route path='/' element={ <Home/>}/>
          <Route path='/about' element={ <About/>}/>
          <Route path='/portfolio' element={ <Portfolio/>}/>
          <Route path='/services' element={ <Services/>}/>
          <Route path='/contact' element={ <Contact/>}/>
        </Routes>
   
   <Footer/>
   
   
    </>
  );
}

export default App;
