import React from "react";
import { Link } from "react-router-dom";

function Common(props) {
  return (
    <>
    <section id="header" className="header ">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column ">
                <h1 className="bold" >
                  { props.name} <strong  className="brand-name">DahalTechnical</strong>
                </h1>
                <h3 className="my-3 ">
                  we are the team of talented devepoler making websites
                </h3>
                <div className="mt-3">
                  <Link to={props.visit} className="btn-get-started">{props.btn}</Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 header-img">
                <img src={props.imgsrc}className="img-fluid animated" alt="img-show" />
              </div>
             </div>  
          </div>
        </div>
      </div>
    </section>
    </>
    
  );
}

export default Common;
