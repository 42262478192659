import React from "react";

import { Link } from "react-router-dom";
import Card from "../card";
import Sdata from "../sdata";
function Portfolio() {
  return (
    <>
       <div className="my-5">
        <h1 className="text-center">Portfolio</h1>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              {
                Sdata.map((value,index)=>{
                  return <Card key={index}
                  imgsrc={value.imgsrc}
                  title={value.title}
                  desc= {value.desc}
                  btn = "Enroll Now"
                  />
                  
                })
              };
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Portfolio;
