import React from "react";
import { Link } from "react-router-dom";
import Common from "../common";

function Home() {
  return (
    <>
    <Common name = "Grow your business with" imgsrc="http://www.goodmorningimagesdownload.com/wp-content/uploads/2021/07/Nice-Whatsapp-DP-Wallpaper-Pic-Download.jpg" visit = "/services" btn = "Get Started"/>
    </>
    
  );
}

export default Home;
