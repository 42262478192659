import React from "react";
import { Link } from "react-router-dom";
import Common from "../common";

function About() {
  return (
    <>
      <Common name = "Welcome to About page" imgsrc="https://images.unsplash.com/photo-1618042164219-62c820f10723?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZG93bmxvYWR8ZW58MHx8MHx8&w=350&q=80" visit = "/contact" btn = "Contact Now"/>
    </>
  );
}

export default About;
