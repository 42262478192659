import React from "react";
// import{useState} from'react'
function Contact() {
  const[data , setData] = React.useState({
    fullname:"",
    phone:"",
    email:"",
    msg:""
  });
  const InputEvent = (event)=>{
    const {name,value} = event.target; 
    setData((perVal)=>{
      return{
        ...perVal,
        [name]:value,
      }
    })
  }
  const formSubmit = (e)=>{
    e.preventDefault();
    alert(`My name is ${data.fullname}.My phone number is ${data.phone} and email is ${data.email}, hello is what I want to say ${data.msg} `)
  }
  return (
    <>
      <div className="my-5 mb-10">
        <h1 className="text-center">Contact Us</h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form action="#" onSubmit={formSubmit}>
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  FullName :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="fullname"
                  value={data.fullname}
                  onChange ={InputEvent}
                  placeholder="Name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Phones
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="phone"
                  value={data.phone}
                  onChange ={InputEvent}
                  placeholder="Mobile Number"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="email"
                  value={data.email}
                  onChange ={InputEvent}   
                  placeholder="name@example.com"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  name="msg"
                  value={data.msg}
                  onChange ={InputEvent}
                  rows="3"
                ></textarea>
              </div>
              <div className="col-12">
                <button className=" btn  btn-outline-success " type="submit">
                  Submit form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
